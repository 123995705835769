/* eslint-disable max-len */
// External libraries
import React, { ReactElement } from 'react';

// UI Components
import { Box, Typography } from '@material-ui/core';

// Style
import '../style/contact-style.scss';

const ContactComponent: React.FC = (): ReactElement => {
	return (
		<div className='contact-container'>
			<div>
				<Typography variant='h2' gutterBottom={true} color='secondary' align='left'>
					Kontakt
				</Typography>
				<Box m={6}/>
				<Typography variant='h6' color='primary' align='left'>
					Za sve informacije u vezi projekta, kupovine stanova i parking mesta, kontaktirajte nas.
					<br/>
					Rado ćemo Vam odgovoriti u najkraćem mogućem roku.
				</Typography>
				<Box m={6}/>
				<Typography variant='h5' color='secondary' align='left'>
					Kope Bau d.o.o.
					<Box m={2}/>
					Vojvode Radomira Putnika 15, lokal 6.
					<Box m={2}/>
					Tel: <a href='tel:0644472140'>+381 64 44 72 140</a>
					<Box m={2}/>
					www.kopebau.rs
					<Box m={2}/>
					Email: <a href='mailto:kopebau46@gmail.com'>kopebau46@gmail.com</a>
				</Typography>
				<Box m={6}/>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
					<Typography variant='h5' color='primary' align='left'>
						Arhitekta: Bojan Grubanov
						<br/>
						Arhitektonski atelje &quot;Studio 3&quot;, Pančevo
					</Typography>
					<img src='images/studio_3_logo.png' alt='Studio 3 logo' width='30%' />
				</div>
				<Box m={6}/>
				<Typography variant='h5' color='primary' align='left'>
					Izvođači radova: &quot;Nova Gradnja&quot;, Padina
				</Typography>
			</div>
			<div className='contact-map'>
				<iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2827.612972146088!2d20.641986215826467!3d44.87017327909836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7eebc57356e5%3A0xbd5576ebe9a70f62!2sVojvode%20Radomira%20Putnika%2015%2C%20Pan%C4%8Devo%20300205!5e0!3m2!1sen!2srs!4v1615674285587!5m2!1sen!2srs'
					width='600' height='450' allowFullScreen={false} loading='lazy'>
				</iframe>
			</div>
		</div>
	);
};

export default ContactComponent;
