/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/brace-style */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable arrow-body-style */
// External libraries
import React, { ReactElement, useEffect, useRef, useState } from 'react';

// UI Components
import { Box, Button, GridList, GridListTile, GridListTileBar, Typography } from '@material-ui/core';

// Style
import '../style/apartments-style.scss';
import { useHistory } from 'react-router';

const floors = [{
	name: 'Podrum',
	apartments: [17],
	img: 'basement.jpg'
}, {
	name: 'Prizemlje',
	apartments: [0, 1],
	img: 'ground.jpeg'
},{
	name: 'Prvi sprat',
	apartments: [2, 3, 4, 5],
	img: 'first.jpeg'
},{
	name: 'Drugi sprat',
	apartments: [6, 7, 8, 9],
	img: 'second.jpeg'
},{
	name: 'Treći sprat',
	apartments: [10, 11, 12, 13],
	img: 'third.jpeg'
},{
	name: 'Povučeni sprat',
	apartments: [14, 15, 16],
	img: 'withdrawn.jpeg'
}];


const ApartmentsComponent: React.FC = (): ReactElement => {
	const [currentFloor, setCurrentFloor] = useState(-1);
	const [width, setWidth] = useState(1000);

	const history = useHistory();
	const backListener = useRef<any>(null);

	useEffect(() => {
		onResize();
		window.addEventListener('resize', onResize);
		backListener.current = history.listen(location => {
			if (location.pathname === '/stanovi' && location.hash === '') {
				setCurrentFloor(-1);
			}
		});

		return () => {
			backListener.current();
		}
	}, []);

	function onResize() {
		setWidth(window.innerWidth);
	}

	function chooseFloor(floorIndex: number) {
		setCurrentFloor(floorIndex);
		history.push(`#${floorIndex}`);
	}

	function getApartmentImageSrc(apartmentIndex: number, type: string) {
		if (apartmentIndex === 17) {
			return `images/${apartmentIndex + 1}-${type}.png`;
		} else if (apartmentIndex < 2 || apartmentIndex > 13 || apartmentIndex === 6) {
			return `images/${apartmentIndex + 1}-${type}.jpg`;
		}
		else {
			return `images/${mapIndex(apartmentIndex) + 1}-${type}.jpg`;
		}
	}

	function mapIndex(apartmentIndex: number) {
		switch(apartmentIndex) {
			case 2:
			case 10:
				return 2;
			case 3:
			case 7:
			case 11:
				return 3;
			case 4:
			case 8:
			case 12:
				return 4;
			case 5:
			case 9:
			case 13:
				return 5;
			default: return -1;
		}
	}

	function isMobile() {
		return width < 800;
	}

	function backClick() {
		history.goBack();
	}

	return (
		<>
			{ currentFloor < 0 ?
				<div className='floors-container'>
					<GridList cols={isMobile() ? 1 : 2} cellHeight={isMobile() ? 300 : 360} spacing={isMobile() ? 50 : 100}>
						{floors.map((floor, i) => {
							return (
								<GridListTile key={floor.img} classes={{tile: 'center-image'}}>
									<img src={`images/${floor.img}`} alt={floor.name} className='grid-item' onClick={() => chooseFloor(i)}/>
									<GridListTileBar
										title={
											<Typography variant='h4' color='secondary' align='right'>
												{floor.name}
											</Typography>
										}
										style={{background: 'rgba(0, 0, 0, 0.7)'}}
									/>
								</GridListTile>
							);
						})}
					</GridList>
				</div>
				:
				<div className='apartments-container'>
					{width < 800 ?
						<>
							<Box m={'5%'}/>
							<Typography variant='h2' gutterBottom={true} color='secondary' align='center'>
								{floors[currentFloor].name}
							</Typography>
							<Box m={'5%'}/>
							<div className='apartments-box'>
								{floors[currentFloor].apartments.map((index) => {
									if (index+1 === 9 || index+1 === 1 || index+1 === 11 || index+1 === 13 || index+1 === 16 ||
										index+1 === 3 || index+1 === 5 || index+1 === 7 || index+1 === 8 || index+1 === 12 ||
										index+1 === 14 || index+1 === 4 || index+1 === 10 || index+1 === 15 || index+1 === 2
										|| index+1 === 6) {
										return (
											<>
												<div key={index} className='apartment'>
													<div className='overlay'>
														<div className='sold-text'>PRODATO</div>
													</div>
													<Typography variant='h3' gutterBottom={true} color='secondary' align='center' style={{marginBottom: '5%'}}>
														{`Stan br. ${index+1}`}
													</Typography>
													<img src={getApartmentImageSrc(index, 'plan')} alt='Plan image' className='plan-image' style={{marginBottom: '5%'}}/>
													<img src={getApartmentImageSrc(index, 'table')} alt='Table image' className='table-image'/>
												</div>
												<Box m={'10%'}/>
											</>
										);
									} else {
										return (
											<>
												<div key={index} className='apartment'>
													<Typography variant='h3' gutterBottom={true} color='secondary' align='center' style={{marginBottom: '5%'}}>
														{`Stan br. ${index+1}`}
													</Typography>
													<img src={getApartmentImageSrc(index, 'plan')} alt='Plan image' className='plan-image' style={{marginBottom: '5%'}}/>
													<img src={getApartmentImageSrc(index, 'table')} alt='Table image' className='table-image'/>
													{
														currentFloor === 0 &&
														<>
															<Box m={4}/>
															<img src='images/18-table-1.png' alt='Table image' className='table-image'/>
														</>
													}
												</div>
												<Box m={'10%'}/>
											</>
										);
									}
								})}
							</div>
						</>
						:
						<>
							<Typography variant='h2' gutterBottom={true} color='secondary' align='center'>
								{floors[currentFloor].name}
							</Typography>
							<Box m={4}/>
							<div className='apartments-box'>
								{floors[currentFloor].apartments.slice(0, 2).map((index) => {
									if (index+1 === 9 || index+1 === 1 || index+1 === 11 || index+1 === 13 || index+1 === 16 ||
										index+1 === 3 || index+1 === 5 || index+1 === 7 || index+1 === 8 || index+1 === 12 ||
										index+1 === 14 || index+1 === 4 || index+1 === 10 || index+1 === 15 || index+1 === 2 ||
										index+1 === 6) {
										return (
											<div key={index} className='apartment'>
												<div className='overlay'>
													<div className='sold-text'>PRODATO</div>
												</div>
												<Typography variant='h3' gutterBottom={true} color='secondary' align='center' style={{marginBottom: '5%'}}>
													{`Stan br. ${index+1}`}
												</Typography>
												<img src={getApartmentImageSrc(index, 'plan')} alt='Plan image' className='plan-image' style={{marginBottom: '5%'}}/>
												<img src={getApartmentImageSrc(index, 'table')} alt='Table image' className='table-image'/>
											</div>
										);
									} else {
										return (
											<div key={index} className='apartment'>
												{currentFloor !== 0 &&
													<Typography variant='h3' gutterBottom={true} color='secondary' align='center' style={{marginBottom: '10%'}}>
														{`Stan br. ${index+1}`}
													</Typography>
												}
												<img src={getApartmentImageSrc(index, 'plan')} alt='Plan image' className='plan-image' style={{marginBottom: '5%'}}/>
												<img src={getApartmentImageSrc(index, 'table')} alt='Table image' className='table-image'/>
												{currentFloor === 0 &&
												<>
													<Box m={2}/>
													<img src='images/18-table-1.png' alt='Table image' className='table-image'/>
												</>}
											</div>
										);
									}
								})}
							</div>
							{floors[currentFloor].apartments.slice(2, floors[currentFloor].apartments.length).length !== 0 && <Box m={'5%'}/>}
							<div className='apartments-box'>
								{floors[currentFloor].apartments.slice(2, floors[currentFloor].apartments.length).map((index) => {
									if (index+1 === 9 || index+1 === 1 || index+1 === 11 || index+1 === 13 || index+1 === 16 ||
										index+1 === 3 || index+1 === 5 || index+1 === 7 || index+1 === 8 || index+1 === 12 ||
										index+1 === 14 || index+1 === 4 || index+1 === 10 || index+1 === 15 || index+1 === 2 ||
										index+1 === 6) {
										return (
											<div key={index} className='apartment'>
												<div className='overlay'>
													<div className='sold-text'>PRODATO</div>
												</div>
												<Typography variant='h3' gutterBottom={true} color='secondary' align='center' style={{marginBottom: '5%'}}>
													{`Stan br. ${index+1}`}
												</Typography>
												<img src={getApartmentImageSrc(index, 'plan')} alt='Plan image' className='plan-image' style={{marginBottom: '5%'}}/>
												<img src={getApartmentImageSrc(index, 'table')} alt='Table image' className='table-image'/>
											</div>
										);
									} else {
										return (
											<div key={index} className='apartment'>
												<Typography variant='h3' gutterBottom={true} color='secondary' align='center' style={{marginBottom: '5%'}}>
													{`Stan br. ${index+1}`}
												</Typography>
												<img src={getApartmentImageSrc(index, 'plan')} alt='Plan image' className='plan-image' style={{marginBottom: '5%'}}/>
												<img src={getApartmentImageSrc(index, 'table')} alt='Table image' className='table-image'/>
											</div>
										);
									}
								})}
							</div>
							<Box m={'5%'}/>
						</>
					}
					<Button variant='contained' color='primary' className='apartments-btn' size='large' onClick={backClick}>
						Nazad na odabir sprata
					</Button>
				</div>
			}
		</>
	);
};

export default ApartmentsComponent;
