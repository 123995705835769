interface IConfigType {
	address: string,
	phone: string,
	email: string,
	website: string
}

export default {
	address: 'Vojvode Radomira Putnika 15, lokal 6',
	phone: '+381 64 44 72 140',
	email: 'kopebau46@gmail.com',
	website: 'kopebau.rs'
} as IConfigType;
