import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#1b2831',
		},
		secondary: {
			main: '#ba9e7a',
		},
		type: 'dark',
		text: {
			primary: '#ffffff',
			secondary: '#ba9e7a'
		},
	}
});

export default theme;