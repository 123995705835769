// External libraries
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

// UI components
import App from './components/ts/app-component';

// Theme
import theme from './style/theme';
import { MuiThemeProvider } from '@material-ui/core';

// Style
import './style/index.scss';

ReactDOM.render(
	<MuiThemeProvider theme={theme}>
		<Router>
			<App />
		</Router>
	</MuiThemeProvider>,
	document.getElementById('root')
);
