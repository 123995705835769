/* eslint-disable max-len */
// External libraries
import React, { ReactElement, useEffect, useState } from 'react';

// UI Components
import { Box, List, ListItem, Typography } from '@material-ui/core';

// Style
import '../style/location-style.scss';

const locationInfo = [
	'Supermarket manje od 100m',
	'Ambulanta manje od 100m',
	'Mesara manje od 150m',
	'Apoteka na 200m',
	'Autoperionica na 500m',
	'Vrtić na 250m',
	'Otvoreno dečije igralište na 200m',
	'Osnovna Škola na 200m',
	'Autobuska stanica na 50m',
	'Centar Pančevo na 1000m',
	'Aviv Park na 500m'
];

const LocationComponent: React.FC = (): ReactElement => {
	const [width, setWidth] = useState(1000);

	useEffect(() => {
		onResize();
		window.addEventListener('resize', onResize);
	}, []);

	function onResize() {
		setWidth(window.innerWidth);
	}

	const alignment = width < 800 ? 'center' : 'right';

	return (
		<div className='location-container'>
			<div className='location-map'>
				<iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2827.422103320808!2d20.65780241553783!3d44.87405827909848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7efb322cb97d%3A0x9e95dafb58547f6!2z0JzQuNC70LrQtSDQnNCw0YDQutC-0LLQuNGbIDIxLCDQn9Cw0L3Rh9C10LLQvg!5e0!3m2!1ssr!2srs!4v1616326470414!5m2!1ssr!2srs'
					width='600' height='450' allowFullScreen={false} loading='lazy'>
				</iframe>
			</div>
			<div className='location-text'>
				<Typography variant='h2' gutterBottom={true} color='secondary' align={alignment}>
					Lokacija
				</Typography>
				<Box m={4}/>
				<Typography variant='h6' color='primary' align='justify'>
					Kope Bau objekat nalazi se u neposrednoj okolini Beograda,
					ali daleko od gradske gužve, saobraćajnog kolapsa i bučne okoline.
					Objekat se nalazi u ulici Milke Marković, na samo 1km od centra grada.
					Dovoljno daleko da Vam pruži potpuni mir,
					a dovoljno blizu da kratkom šetnjom stignete do centra.
				</Typography>
				<Box m={4}/>
				<Typography variant='h4' color='secondary' align={alignment}>
					Adresa: Milke Marković 21, naselje Tesla, Pančevo
				</Typography>
				<Box m={4}/>
				<List dense={true} style={{alignSelf: `${width < 800 ? 'center' : 'flex-end'}`}}>
					{locationInfo.map(info => {
						return (
							<ListItem key={info} style={{display: 'flex', justifyContent: `${width < 800 ? 'center' : 'flex-end'}`}}>
								<Typography variant='h5' color='secondary' align='center'>
									{info}
								</Typography>
							</ListItem>
						);
					})}
				</List>
			</div>
		</div>
	);
};

export default LocationComponent;
