// External libraries
import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// UI components
import { List, ListItem, Toolbar, Typography } from '@material-ui/core';
import { LocationOn, Email, Phone } from '@material-ui/icons';

// Style
import '../style/footer-style.scss';

// Util
import navLinks from '../util/navigation-links';
import CONTACT_INFO from '../util/contact-info';

const FooterComponent: React.FC = (): ReactElement => {
	const [width, setWidth] = useState(1000);

	useEffect(() => {
		onResize();
		window.addEventListener('resize', onResize);
	}, []);

	function onResize() {
		setWidth(window.innerWidth);
	}

	const contactVariant = width < 800 ? 'subtitle2' : 'subtitle1';
	const contactItem = width < 800 ? '' : 'contact-item';
	return (
		<div className='footer-container'>
			<Toolbar style={{height: '100%'}}>
				<div className='footer-content'>
					<Link to='/'>
						<img src='logo/kope_bau_logo_light.png' alt='Logo' className='footer-logo-image'/>
					</Link>
					<div style={{width: '60%'}}>
						{width > 800 &&
						<List component='nav' className='footer-navigation-list'>
							{
								navLinks.map(({title, path}) => {
									return (
										<Link to={path} key={title} className='tab-link-text'>
											<ListItem button={true}>
												<Typography variant='h6'>
													{title}
												</Typography>
											</ListItem>
										</Link>
									);
								})
							}
						</List>}
						<List className='contact-list'>
							<ListItem className={contactItem}>
								<LocationOn className='contact-icon'/>
								<Typography variant={contactVariant} color='textPrimary' align='center'>
									{CONTACT_INFO.address}
								</Typography>
							</ListItem>
							<ListItem className={contactItem}>
								<Phone className='contact-icon'/>
								<Typography variant={contactVariant} color='textPrimary' align='center'>
									{CONTACT_INFO.phone}
								</Typography>
							</ListItem>
							<ListItem className={contactItem}>
								<Email className='contact-icon'/>
								<Typography variant={contactVariant} color='textPrimary' align='center'>
									{CONTACT_INFO.email}
								</Typography>
							</ListItem>
						</List>
					</div>
				</div>
			</Toolbar>
		</div>
	);
};

export default FooterComponent;
