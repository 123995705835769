/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/indent */
// External libraries
import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// UI components
import { AppBar, Box, Container, Drawer, IconButton, List, ListItem, Toolbar, Typography } from '@material-ui/core';
import { Menu } from '@material-ui/icons';

// Style
import '../style/header-style.scss';

// Util
import navLinks from '../util/navigation-links';

const HeaderComponent: React.FC = (): ReactElement => {
	const [width, setWidth] = useState(1000);
	const [drawerIsOpen, setDrawerIsOpen] = useState(false);

	useEffect(() => {
		onResize();
		window.addEventListener('resize', onResize);
	}, []);

	function onResize() {
		setWidth(window.innerWidth);
	}

	return (
		<>
		<AppBar position='sticky' className='header-container'>
			<Toolbar style={{height: '100%'}}>
				<Container className='navigation-container'>
					<Link to='/' replace={true}>
						<img src='logo/kope_bau_logo_light.png' alt='Logo' className='header-logo-image'/>
					</Link>
					{width < 800 ?
					<IconButton onClick={() => setDrawerIsOpen(true)}>
						<Menu style={{transform: 'scale(1.8)'}}/>
					</IconButton>
						:
					<List component='nav' className='navigation-list'>
					{
						navLinks.map(({title, path}) => {
							return (
								<Link to={path} key={title} className='tab-link-text'>
									<ListItem button={true}>
										<Typography variant='h6'>
											{title}
										</Typography>
									</ListItem>
								</Link>
							);
						})
					}
					</List>
					}
				</Container>
			</Toolbar>
		</AppBar>
		{width < 800 &&
		<Drawer anchor='right' open={drawerIsOpen} classes={{paper: 'drawer'}}>
			<div className='drawer-overlay' onClick={() => setDrawerIsOpen(false)}></div>
			<List component='nav'>
				{
					navLinks.map(({title, path}) => {
						return (
							<Link to={path} key={title} className='tab-link-text' onClick={() => setDrawerIsOpen(false)}>
								<Box m={2}/>
								<ListItem button={true}>
									<Typography variant='h5'>
										{title}
									</Typography>
								</ListItem>
								<Box m={4}/>
							</Link>
						);
					})
				}
			</List>
		</Drawer>
		}
		</>
	);
};

export default HeaderComponent;
